// @flow
import * as React from "react";
import styles from "./index.module.scss";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import { Carousel } from "antd";
import { useRef, useState } from "react";
import { convertToI18, formatNumber } from "@/utils/count";
import { handleToMoblieList, handleToUrl } from "@/utils/sys";

type Props = {
    Data1: any;
    Data2: any;
    Data3: any;
};
const Index = (props: Props) => {
    const { t, i18n } = useTranslation("home");
    const { Data1, Data2, Data3 } = props;

    let [isFast, setFast] = useState(true);
    const carouselRef = useRef(null);
    const handClick = (suburl: string) => {
        handleToMoblieList("Influencers", suburl);
    };
    const toChangeTab = (current: number) => {
        setFast(current === 0);
    };
    const ChangeCarouse = (index: number) => {
        //@ts-ignore
        carouselRef?.current?.goTo(index);
    };

    return (
        <div className={`${styles.user}`}>
            <div className={`${styles.container}`}>
                <div className={styles.tap}>
                    <ul>
                        <li>
                            <a
                                style={{
                                    color: isFast
                                        ? "#2E384E"
                                        : "rgba(46,56,78,0.4600)",
                                }}
                                onClick={() => ChangeCarouse(0)}
                            >
                                {t("SaleTop")}
                                <div
                                    className={styles.bar}
                                    style={{ display: !isFast ? "none" : "" }}
                                ></div>
                            </a>
                        </li>
                        <li>
                            <a
                                style={{
                                    marginLeft: "calc(100vw * (20 / 375))",
                                    color: isFast
                                        ? "rgba(46,56,78,0.4600)"
                                        : "#2E384E",
                                }}
                                onClick={() => ChangeCarouse(1)}
                            >
                                {t("Breakout")}
                                <div
                                    className={styles.bar}
                                    style={{ display: isFast ? "none" : "" }}
                                ></div>
                            </a>
                        </li>
                        {/* <li>
              <a
                style={{
                  marginLeft: "calc(100vw * (20 / 375))",
                  color: isFast ? "rgba(46,56,78,0.4600)" : "#2E384E",
                }}
                onClick={() => ChangeCarouse(1)}
              >
                {t("Promising")}
                <div
                  className={styles.bar}
                  style={{ display: isFast ? "none" : "" }}
                ></div>
              </a>
            </li> */}
                    </ul>
                    <div
                        className={styles.more}
                        onClick={() =>
                            handClick(isFast ? "SaleTop" : "Breakout")
                        }
                    >
                        {t("_more")}
                    </div>
                </div>

                <Carousel
                    afterChange={toChangeTab}
                    ref={carouselRef}
                    speed={100}
                    dots={false}
                >
                    <div className="px-[20px]">
                        <div className={styles.itemTitle2}>{t("update1")}</div>
                        {(Data3 || []).map((item: any, index: any) => (
                            <div
                                key={index}
                                className={styles.item}
                                onClick={() => handClick("SaleTop")}
                            >
                                <div className="flex items-center">
                                    <div className={styles.sort}>
                                        #{index + 1}
                                    </div>
                                    <div
                                        className={
                                            styles.atr + " flex items-center"
                                        }
                                    >
                                        <Image
                                            lazyBoundary="10px"
                                            src={`https://ttcdn.tikmeta.com/tikmeta/${item.avatarLargerPrivatization}`}
                                            width={48}
                                            height={48}
                                            alt=""
                                            style={{ borderRadius: "50%" }}
                                            className="!border-[1px] !border-solid !border-[#ECECEC]"
                                        ></Image>
                                    </div>
                                    <div className={styles.info}>
                                        <div className={styles.name}>
                                            {"@" + item.nickname}
                                        </div>
                                        <div className={styles.count}>
                                            <div>{t("Revenue")}</div>
                                            <div className="ml-[4px]">
                                                {item?.estimatedSalesAmount}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="right">
                                    <div className={styles.add}>
                                        <div>
                                            <span style={{ color: "#12B939" }}>
                                                ↑
                                            </span>
                                            {item?.estimatedSalesVolume}
                                        </div>
                                    </div>
                                    <div className={styles.grow}>
                                        {t("Sales")}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="px-[20px]">
                        <div className={styles.itemTitle2}>{t("update1")}</div>
                        {(Data1 || []).map((item: any, index: any) => (
                            <div
                                key={index}
                                className={styles.item}
                                onClick={() => handClick("Breakout")}
                            >
                                <div className="flex items-center">
                                    <div className={styles.sort}>
                                        #{index + 1}
                                    </div>
                                    <div className={styles.atr}>
                                        <Image
                                            lazyBoundary="10px"
                                            src={`https://ttcdn.tikmeta.com/tikmeta/${item.avatarLargerPrivatization}`}
                                            width={48}
                                            height={48}
                                            alt=""
                                            style={{ borderRadius: "50%" }}
                                            className="!border-[1px] !border-solid !border-[#ECECEC]"
                                        ></Image>
                                    </div>
                                    <div className={styles.info}>
                                        <div className={styles.name}>
                                            {"@" + item.nickname}
                                        </div>
                                        <div className={styles.count}>
                                            <div>{t("_Follower")}</div>
                                            <div className="ml-[4px]">
                                                {convertToI18(
                                                    item.followerCount || null
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="right">
                                    <div className={styles.add}>
                                        <div>
                                            <span style={{ color: "#12B939" }}>
                                                ↑
                                            </span>
                                            {formatNumber(item?.followerIncr)}
                                        </div>
                                    </div>
                                    <div className={styles.grow}>
                                        {t("followersGrowth")}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    {/* <div className="px-[20px]">
            <div className={styles.itemTitle2}>{t("update1")}</div>
            {(Data2 || []).map((item: any, index: any) => (
              <div
                key={index}
                className={styles.item}
                onClick={() => handClick("Promising")}
              >
                <div className="flex items-center">
                  <div className={styles.sort}>#{index + 1}</div>
                  <div className={styles.atr + " flex items-center"}>
                    <Image
                      lazyBoundary="10px"
                      src={`https://ttcdn.tikmeta.com/tikmeta/${item.avatarLargerPrivatization}`}
                      width={48}
                      height={48}
                      alt=""
                      style={{ borderRadius: "50%" }}
                      className="!border-[1px] !border-solid !border-[#ECECEC]"
                    ></Image>
                  </div>
                  <div className={styles.info}>
                    <div className={styles.name}>{"@" + item.nickname}</div>
                    <div className={styles.count}>
                      <div>{t("_Follower")}</div>
                      <div className="ml-[4px]">
                        {convertToI18(item.followerCount || null)}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="right">
                  <div className={styles.add}>
                    <div>{item?.potentialValue}</div>
                  </div>
                  <div className={styles.grow}>{t("potentialValue")}</div>
                </div>
              </div>
            ))}
          </div> */}
                </Carousel>
            </div>
        </div>
    );
};

export default Index;
